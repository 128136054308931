import { Responsible, MutationInviteResponsibleArgs } from "@/graphql/types";
import { gql } from "@apollo/client/core";
import { RESPONSIBLE_FIELDS } from "@/graphql/responsible/responsible.sdl";
import { useMutation } from "@vue/apollo-composable";
import { useValidation } from "vue3-form-validation";
import { USER_FIELDS } from "@/graphql/user/user.sdl";
import { useToast } from "primevue/usetoast";
import { useI18n } from "vue-i18n";
import { activeActivity } from "@/plugins/i18n";
import { computed, ref } from "vue";
import { validMail } from "@/graphql/utils/send-mail";
import { useRoles } from "@/hooks/role/useRoles";

type InviteResponsibleData = {
  inviteResponsible: Responsible;
};
const INVITE_RESPONSIBLE = gql`
    mutation InviteResponsible($input: InviteResponsibleInput!) {
        inviteResponsible(input: $input) {
            ${RESPONSIBLE_FIELDS}
            user {
                ${USER_FIELDS}
            }
        }
    }
`;

export const useInviteResponsible = () => {
  const { t } = useI18n();
  const toast = useToast();
  const visible = ref(false);
  const { loading: loadingRoles, roles } = useRoles();

  const { validateFields, hasError, form } = useValidation({
    role: {
      $value: null,
      $rules: [(v: number) => !v && ""],
    },
    email: {
      $value: "",
      $rules: [(m: string) => !validMail(m) && ""],
    },
  });

  const {
    mutate,
    loading: inviteLoading,
    onDone,
  } = useMutation<InviteResponsibleData, MutationInviteResponsibleArgs>(
    INVITE_RESPONSIBLE,
    {
      update(cache, { data }) {
        if (data?.inviteResponsible) {
          cache.modify({
            fields: {
              activityResponsible(existing: any, { toReference }) {
                return [...existing, toReference(data.inviteResponsible)];
              },
            },
          });
        }
      },
    }
  );

  onDone(({ data, errors }) => {
    if (errors) {
      toast.add({
        severity: "error",
        summary: t("responsible.create.title"),
        detail: t(
          `${
            errors[0].message.includes("sendMail.error")
              ? "sendMail.error"
              : errors[0].message === "exist"
              ? "responsible.exist"
              : "unknownError"
          }`
        ),
        life: parseInt(process.env.VUE_APP_TOAST_LIFE),
      });
    } else {
      toast.add({
        severity: "success",
        summary: t("responsible.create.title"),
        detail: t(
          `responsible.create.${data?.inviteResponsible ? "success" : "failed"}`
        ),
        life: parseInt(process.env.VUE_APP_TOAST_LIFE),
      });
    }
    visible.value = false;
  });

  function submitInvitation() {
    validateFields().then((input) => {
      void mutate({
        input: {
          ...input,
          password: input.email.substr(0, 5),
          activityId: activeActivity.value.id,
        },
      });
    });
  }

  const loading = computed(() => loadingRoles.value || inviteLoading.value);

  return {
    loading,
    hasError,
    form,
    submitInvitation,
    roles,
    visible,
    activeActivity,
  };
};
